/* globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "antd/dist/reset.css";

/* // fix Tailwind CSS border styles,form Tailwind CSS's preflight */
*,
::before,
::after {
  /* box-sizing: border-box; 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: theme("borderColor.DEFAULT", currentColor); /* 2 */
}

::before,
::after {
  --tw-content: "";
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.form {
  margin: 0 !important;
  /* margin-bottom: 10px !important; */
}

.hide-scrollbar {
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none !important; /* Webkit-based browsers */
}
